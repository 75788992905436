import {BridgePosition} from '../app/types';

enum GameStates {
    NOT_STARTED, //Create a deal and sent to all players, go to DEAL_INITIALIZE
    DEAL_INITIALIZE, //Waiting for Players to confirm they received the deal and their App is ready for the auction, we go to AUCTION_SENT_TIME_TO_BID
    DEAL_INITIALIZED, // We ask current player to bid, and set the state to AUCTION_WAITING_FOR_BID
    AUCTION_SENT_TIME_TO_BID, // We ask current player to bid, and set the state to AUCTION_WAITING_FOR_BID
    AUCTION_WAITING_FOR_BID, // We are waiting for a bid from current player, once it is received we go to AUCTION_RECEIVED_BID
    AUCTION_RECEIVED_BID, // We received a bid, we set the state and go on to process the bid, we go back to AUCTION_SEND_BID and send to all players
    AUCTION_SEND_BID, // We wait for all to confirm bid was received, once we have all confirmations we go to AUCTION_CONFIRMED_BID
    AUCTION_CONFIRMED_BID, // We received a bid, we set the state and go on to process the bid, we go back to AUCTION_SENT_TIME_TO_BID or AUCTION_END
    AUCTION_END, //Once we hit AUCTION_END , we set declarer, contract and prepare the director to send the contract. We go to state CONTRACT_SENT
    AUCTION_CONTRACT_SENT, // Once contract is sent, we wait here for confirmation from all four players.

    PLAY_INITIALIZED, // Once all confirm contract is received set state PLAY_INITIALIZED, and go to stage PLAY_SENT_FOR_OPENING_LEAD

    PLAY_SENT_TIME_FOR_OPENING_LEAD, // Currently NOT used: here we sent request to play and go to PLAY_WAITING_FOR_OPENING_LEAD
    PLAY_WAITING_FOR_OPENING_LEAD, // Once opening lead is received, process it and go to PLAY_RECEIVED_OPENING_LEAD
    PLAY_RECEIVED_OPENING_LEAD, // Once opening lead is received, process it , set dummy and send all clients, and go to PLAY_SENT_TIME_TO_PLAY

    PLAY_SENT_TIME_TO_PLAY, // We Ask current player to play and go to PLAY_WAITING_FOR_PLAY
    PLAY_WAITING_FOR_PLAY, // We are waiting for player to play , and go to PLAY_RECEIVED_PLAY
    PLAY_RECEIVED_PLAY, //  Once play is received, process it , and go to PLAY_SENT_TIME_TO_PLAY or PLAY_SENT_TIME_TO_CLOSETRICK

    PLAY_SEND_PLAY, // We wait for all to confirm opening was received

    PLAY_SENT_TIME_TO_CLOSETRICK, // Once play is received, process it , and go to PLAY_WAITING_FOR_CLOSETRICK
    PLAY_WAITING_FOR_CLOSETRICK, // We are waiting for all players to confirming closing trick
    PLAY_RECEIVED_CLOSETRICK, // Once we have all players confirming closing trick, process it , and go to PLAY_SENT_TIME_TO_PLAY or go to PLAY_SENT_TIME_TO_SCORE

    PLAY_SENT_TIME_TO_SCORE, // Once play is received, process it , and go to PLAY_WAITING_FOR_SCORE
    PLAY_WAITING_FOR_SCORE, // Score the deal and move on to next deal DEAL_INITIALIZE, and we are done with the Director state
    PLAY_RECEIVED_SCORE, // Score the deal and move on to next deal DEAL_INITIALIZE, and we are done with the Director state

    WAITING_UNDO_RESPONSE, // A request for UNDO was received and we are waiting for responses from human players
    PROCESSING_UNDO_REQUEST, // A request for UNDO was approved and we are processing it

    WAITING_CLAIM_RESPONSE, // A request for UNDO was received and we are waiting for responses from human players
    PROCESSING_CLAIM_REQUEST // A request for CLAIM was approved and we are processing it
}

enum netMID {
    MID_NEW_DEAL, // 0
    MID_CURRENT_DEAL, // 1
    MID_GET_DEAL, // 2 Depricated
    MID_READY_TO_START, // 3
    MID_START_DEAL, // 4
    MID_TIME_BID, // 5
    MID_MADE_BID, // 6
    MID_CONTRACT, // 7
    MID_TIME_PLAY, // 8
    MID_MADE_PLAY, // 9
    MID_TIME_CLOSE_TRICK, // 10
    MID_TIME_SCORE, // 11
    MID_NEXT_DEAL, //12
    MID_REPLAY_DEAL, //13
    MID_CLAIM_REQUEST, //14
    MID_CLAIM_RESPONSE, //15
    MID_CLAIM_NOTIFICATION, //16
    MID_UNDO_REQUEST, //17
    MID_UNDO_RESPONSE, //18
    MID_UNDO_NOTIFICATION, //19
    MID_SEND_ME_DEAL, //20
    MID_PLAY_FOR_ME_ONCE, //21
    MID_PLAY_FOR_ME, //22
    MID_EMERGENCY_BREAK, //23
    MID_LOAD_PBN_DEAL_SET, //24
    MID_CHANGE_HAND_VISIBILITY, //25
    MID_SEAT_PLAYER = 26, //26
    MID_CONTROL_ACTION = 27, //27
    MID_CLOSE_TABLE = 28, //28
    MID_AUTO_SEAT = 29, //29
    MID_UN_SEAT, //30
    MID_SYNC_ALL, //31
    MID_CONTROL_ZOOM, //32
    MID_KICK_OUT, //33
    MID_TO_JITSI_BRK, //34
    MID_FROM_JITSI_BRK, //35
    MID_END_OF_SET, //36
    MID_BAN_USER,
    MID_START_ROUND,
    MID_SET_HANDS,
    MID_TEXT_MESSAGE,
    MID_MAKE_DDS_CLAIM,
    MID_DELETE_ALL_EMPTY, //42
    MID_CONTROL_ROBOT, //43
    MID_WAIT_FOR_ROUND, //44
    MID_SHOW_WELCOME //45
}

enum ENUM_GAME_MODE {
    Normal = 0, // 0
    Duplicate = 1, // 1
    Swissteam = 2 // 2
}

//BuildInMeeting

export enum MeetingState {
    inLobby = 'lobby',
    atTable = 'table',
    NONE = 'none'
}

export enum MeetingService {
    JitSi = 'jitsi',
    Twitch = 'twitch',
    x8 = '8x8'
}

export class MEETING {
    public static MeetingService = 's';
    public static MeetingHost = 'h';
    public static AccessCode = 'p';
    public static RoomChannel = 'r';
    public static Screened = 'srn';
    public static FullScreen = 'fscrn';
}

export enum EXTENSION {
    EXTENSION_LAG = 'lag',
    EXTENSION_CREATETABLE = 'ct',
    CMD_GAME_LOGIC = 'ext_game'
}

export enum CMD {
    EXTENSION_LAG = 'lag',
    EXTENSION_CREATETABLE = 'ct',
    CMD_GAME_LOGIC = 'ext_game'
}


export class SFSVAR {
    //INTERNAL TRACES
    public static TRACE_ONE = 'Table join request: check if room exists and is active. \n';
    public static TRACE_TWO =
        'Table join request: check if human is seated , send informative admin message and join the user as kibitz. \n';
    public static TRACE_INVITATION = 'Table join request: invalid invitation. \n';
    public static TRACE_OWNER_BLOCK = 'Table join request: check owner block. \n';
    public static TRACE_PARTNER_BLOCK = 'Table join request: check parner block. \n';
    public static TRACE_SEATING = 'Table join request: an error while executing seating. \n';
    public static TRACE_JOINING = 'Table join request->Room Handler can_seat: an error while executing seat_player(SFSUser player) . \n';
    public static TRACE_KIBITZING = 'Table join request->Room Handler can_seat: an error while executing setting kibitz . \n';

    //CUSTOM SERVER EVENTS AND EXTENSIONS
    public static EXTENSION_LAG = 'lag';
    public static EXTENSION_CREATETABLE = 'ct';
    public static EXTENSION_CREATELOBBY = 'newlobby';
    public static EXTENSION_JOINTABLE = 'jt';
    public static EXTENSION_SPEC_TO_PLAYER = 'stp';
    public static EXTENSION_SEND_TO_ALL = 'ba';
    public static EXTENSION_SEND_TO_ALL_PLAYERS = 'bp';
    public static EXTENSION_SUCCESSS = 'success';
    public static EXTENSION_DEAL_STATS_UPDATE = 'dstu';
    public static EXTENSION_ADD_BUDDY = 'addb';
    public static EXTENSION_BLOCK_BUDDY = 'bddb';
    public static EXTENSION_UNBLOCK_BUDDY = 'unbddb';
    public static EXTENSION_REMOVE_BUDDY = 'rbddb';
    public static EXTENSION_INVITATION = 'invt';
    public static EXTENSION_BOOT = 'boot';
    public static EXTENSION_CONTROL_TEACHING_SESSON = 'tscontrol';
    public static SFSGAME_EXTENSION_RESPONSE_MESSAGE = 'message';
    public static SFSGAME_EXTENSION_GAME_RESULTS = 'gmr';

    //INTERNAL REQUESTS, used for communication between server extensions
    public static INTERNAL_UNSEAT_PLAYER = 'int_unseatp';
    public static INTERNAL_SEAT_PLAYER = 'int_seatp';
    public static INTERNAL_SEAT_PLAYER_PARAMS = 'int_seatp_p';
    public static INTERNAL_SEAT_OWNER = 'int_seato';

    //Variables used at Login
    /*
     * v   - integer - holding the protocol version
     * osn - string - name of the operating system as provided by the manufacturer
     * osv - string - operating system version number as provided by the manufacturer
     * dm-   string - device model string as provided by the manufacturer
     * screen - string screen paramters , can include resolution, dpi ect.
     * uuid   - device UUID or serial number. Could be also push notification ID.
     *
     * */

    public static PROTOCOL_VERSION = 'pv';
    public static OS_NAME = 'osn';
    public static OS_VERSION = 'osv';
    public static DEVICE_MODEL = 'dm';
    public static DEVICE_SCREEN = 'screen';
    public static DEVICE_UUID = 'uuid';
    public static OS_LOCALE = 'locale';
    public static TEACHER_NAME = 'tname';
    public static LOBBY_PASS = 'ac';
    public static INTERFACE = 'interf';

    public static GAME_TABLE = 'gtable';
    public static GAME_SEAT = 'gseat';

    //possible OS names
    public static OS_IOS = 'iOS';

    //SFS USER and TABLE protocol variable
    public static SFSUSER_LAG = 'lag';
    public static SFSUSER_LST_PING = 'lastping';
    public static SFSUSER_CUSTOMER_ID = 'cid';
    public static SFSUSER_LOGGEDIN_TIME = 'logtime';
    public static SFSBUDDY_CUSTOMER_ID = '$cid';
    public static SFSGAME_REJOIN = 'rejoin';
    public static SFSGAME_HOST = 'host';
    public static SFSGAME_HOST_DISPLAY = 'host_d';
    public static SFSGAME_TEACHER = 'teacher';
    public static SFSGAME_TEACHER_DISPLAY = 'teacher_d';
    public static SFSGAME_BOTLESS_PLAYER = 'bottless_player';

    public static SFSGAME_SEAT_ID = 'sid';
    public static SFSGAME_INVALID_SEAT_ID = 'isid';
    public static SFSGAME_ID = 'gid';
    public static SFSUSER_ID = 'uid';
    public static SFSGAME_INVITATION_ID = 'iid';

    //user stats
    public static SFSGAME_USER_ABONDONED_DEALS = 'uad';
    public static SFSGAME_USER_COMPLETED_DEALS = 'ucd';
    public static SFSGAME_USER_TOTAL_ABONDONED_DEALS = 'tuad';
    public static SFSGAME_USER_TOTAL_COMPLETED_DEALS = 'tucd';
    public static SFSGAME_USER_TOTAL_PLAYTIME = 'tpt';
    public static SFSGAME_STATS_SAVED = 'savedstats';

    //seat names and availability properties
    public static SFSGAME_NORTH_AVAILABILITY = 'na';
    public static SFSGAME_EAST_AVAILABILITY = 'ea';
    public static SFSGAME_SOUTH_AVAILABILITY = 'sa';
    public static SFSGAME_WEST_AVAILABILITY = 'wa';

    public static SFSGAME_NORTH_PLAYER_NAME = 'npn';
    public static SFSGAME_EAST_PLAYER_NAME = 'epn';
    public static SFSGAME_SOUTH_PLAYER_NAME = 'spn';
    public static SFSGAME_WEST_PLAYER_NAME = 'wpn';

    public static SFSGAME_NO_PLAYER_NAME = 'bot';
    public static SFSGAME_LOCK_PLAYER_NAME = 'locked';
    public static SFSGAME_SEAT_PLAYER_NAME = 'Empty Seat';

    //BRIDGE TABLE variables
    /*
     *
     * Those are used in SFSObjects exchnaged between client and room.
     *
     * */
    public static SFSGAME_MODE = 'mode';

    public static SFSGAME_PRIVACY = 'vs';
    public static SFSGAME_SKILL = 'sk';
    public static SFSGAME_AGGRESSIVNESS = 'ag';

    public static SFSGAME_NS_BIDDINGSYSTEM = 'n_bs';
    public static SFSGAME_NS_BIDDINGSYSTEM_DISPLAY = 'n_bs_d';
    public static SFSGAME_NS_BIDDINGSYSTEM_FILE = 'n_bs_f';

    public static SFSGAME_EW_BIDDINGSYSTEM = 'e_bs';
    public static SFSGAME_EW_BIDDINGSYSTEM_DISPLAY = 'e_bs_d';
    public static SFSGAME_EW_BIDDINGSYSTEM_FILE = 'e_bs_f';

    public static SFSGAME_SCORING = 'sm';
    public static SFSGAME_SCORING_DISPLAY = 'sm_d';
    public static SFSGAME_PRACTICE = 'pr';
    public static SFSGAME_KIBITZING = 'ktz';
    public static SFSGAME_NOAUCTION = 'nau';
    public static SFSGAME_NOLASTTRICK = 'nlt';
    public static SFSGAME_NOUNDO = 'nou';
    public static SFSGAME_NODUMMY = 'nod';
    public static SFSGAME_NOCLAIM = 'noclaim';
    public static SFSGAME_NOREPLAY = 'noreplay';
    public static SFSGAME_ALLOW_REPLAY = 'allowreplay';
    public static SFSGAME_ALLOW_STUDENT_CONTROL = 'aucontrol';
    public static SFSGAME_ROOM_DISPLAY = 'rname';
    public static SFSGAME_ROOM_HOST = 'rhost';
    public static SFSGAME_ROOM_NB = 'rnb';
    public static SFSGAME_DEAL_SET_START_INDEX = 'dindex';
    public static SFSGAME_ROOM_RESULTS_URL = 'rurl';
    public static SFSGAME_ROOM_WELCOME_MESSAGE = 'wm';
    public static SFSGAME_ROOM_FAREWELL_MESSAGE = 'fwm';
    public static SFSGAME_ROOM_IS_POSPORTEM = 'ispsm';
    public static SFSGAME_ROOM_IS_PREGAME = 'isprg';
    public static SFSGAME_ROOM_IS_SCREENGAME = 'isscrn';

    public static SFSGAME_ROOM_ROUND = 'rrn';
    public static SFSGAME_ROOM_STT = 'rstt';
    public static SFSGAME_ROOM_UTC_STRTIME = 'utcstart'; //UTC starting time
    public static SFSGAME_ROOM_UTC_ENDTTIME = 'utcend'; //UTC starting time
    public static SFSGAME_REM_TIME = 'remtime';

    public static SFSUSER_DEAL_INPROGRESS = 'dst';
    public static SFSUSER_DEAL_NS_RESULT = 'nsdr';
    public static SFSUSER_DEAL_EW_RESULT = 'ewdr';
    public static SFSUSER_DEAL_CONTRACT = 'dc';
    public static SFSUSER_DEAL_AUCTION_STATE = 'auction';
    public static SFSUSER_DEAL_PLAY_STATE = 'play';

    public static SFSGAME_MID_LOAD = 'o';
    public static SFSGAME_MID_LOAD_STATE = 'os';
    public static SFSGAME_MID_LOAD_EXTRA = 'oe';
    public static SFSGAME_MID_ROBOT = 'robot';
    public static SFSGAME_MID_HANDPLAYER_VISIBILITY = 'pvis';
    public static SFSGAME_MID_HANDKIBITZ_VISIBILITY = 'kvis';
    public static SFSGAME_MID_ALERT = 'calert';

    public static SFSGAME_GAME_STATE = 'state';
    public static SFSGAME_GOTO_BREAKOUT = 'to_bkr';
    public static SFSGAME_BACKFROM_BREAKOUT = 'from_bkr';
    public static SFSGAME_JITSI_CONTROL = 'jitsi';
    public static LOBBY_MEETING_URL = 'lbmurl';
    public static LOBBY_MEETING_PASS = 'lbmp';
    public static LOBBY_MEETING_ID = 'lbmid';
    public static LOBBY_TWITCH_CHANNEL = 'twchannel';
    public static BREAKOUT_MEETING_URL = 'bkrurl';
    public static RVAR_MEETING_STATE = 'mst';
    public static RVAR_MEETING_PARAMS = 'mlpr';

    public static SFSGAME_POLL_QUESTION = 'pollq';
    public static SFSGAME_POLL_ANSWER = 'polla';
    public static SFSGAME_POLL_CLOSE = 'pollc';

    public static SFSGAME_CARD_HIGHLIGHT = 'cardh';

    public static SFSGAME_CURENT_PLAYER = 'cp';
    public static SFSGAME_CURENT_TRICK_WINNER = 'ctw';

    public static SFSGAME_PLAYER = 'p';
    public static SFSGAME_MESSAGE = 'sm';
    public static SFSGAME_ISME = 'isme';
    public static SFSGAME_OBJ_MESSAGE = 'message';
    public static SFSGAME_OBJ_SENDER = 'sender';

    //BOARD SCORING PROPERTIES INCLUDED WITH
    public static BOARD_SCORING = 'bs';
    public static NORTH_HAND = 'nh';
    public static EAST_HAND = 'eh';
    public static SOUTH_HAND = 'sh';
    public static WEST_HAND = 'wh';
    public static VULN_HAND = 'vh';
    public static DEALER_HAND = 'dh';
    public static BOARD_PBN = 'pbn';
    public static MY_SCORE = 'my';
    public static MY_IMPS = 'mi';
    public static MY_MP = 'mp';
    public static MY_TOTAL_MP = 'mtp';
    public static MY_TOTAL_IMPS = 'mti';
    public static EXPECTED_SCORE = 'es';
    public static EXPECTED_IMPS = 'ei';
    public static EXPECTED_MP = 'ep';
    public static EXPECTED_TOTAL_MP = 'etp';
    public static EXPECTED_TOTAL_IMPS = 'eti';
    public static NUMBER_BOARDS = 'nb';
    public static TRICK_RESULT = 'trr';
    public static MASTER_SCORER = 'msr';
    public static GAME_RESULTS = 'gmr';

    //GAME COMMANDS
    public static CMD_GAME_LOGIC = 'ext_game';
    public static CMD_CONTROL_ACTION = 'cmd_action';
    public static CMD_REPLAY_DEAL = 'ext_replayd';

    public static CMD_SEND_ME_DEAL = 'ext_smd';
    public static CMD_SYNC_ME = 'ext_sync';
    public static CMD_NEXT_DEAL = 'ext_nd';
    public static CMD_PREV_DEAL = 'ext_prevd';
    public static CMD_TABLE_READY = 'ext_tr';
    public static CMD_INTERFACE_READY = 'ext_ir';

    public static CMD_FORCE_BID = 'ext_fb';
    public static CMD_MAKE_CALL = 'ext_mk';

    public static CMD_GO_TO_PLAY = 'ext_gtp';

    public static CMD_MAKE_PLAY = 'ext_mp';
    public static CMD_FORCE_PLAY = 'ext_fp';
    public static CMD_CLOSE_TRICK = 'ext_fp';

    public static CMD_ADD_CARD = 'ext_ac';
    public static CMD_REMOVE_CARD = 'ext_rc';

    public static CMD_UPDATE_SCORE = 'ext_scup';

    public static CMD_REQUEST_CLAIM = 'ext_rqc';
    public static CMD_RESPONSE_CLAIM = 'ext_rpc';

    public static CMD_REQUEST_UNDO = 'ext_rqu';
    public static CMD_RESPONSE_UNDO = 'ext_rpu';

    public static CMD_SCREEN_MESSAGE = 'ext_scr_message';

    public static CMD_UPDATE_HAND_VISIBILITY = 'cmd_hv';

    public static CMD_ROUND_CONTROL = 'cmd_round_control';

    public static SFSGAME_VIDEO_CLIP = 'video';
    public static SFSGAME_FRAME_URL = 'frameurl';

    public static SFSGAME_MINIBRIDGE = 'minib';
    //SFSObject ID's
    /*This is the one mandatory element in every response from the server.
     *
     * Clients will take appropriate action based on the MID
     * When client talk to room they do send extension request with appropriate  cmd_ command
     *
     *
     */
    public static EXTENSION_MID = 'mid';

    //Misc
    public static UNKNOWN = 'unknown';
    public static SessionUID = 'sesionUID';
    public static GameID = 'gid';
    public static SKIP_LEAVE_ROOM = 'sklr';

    public static SFS_NICKNAME = 'nn';
    public static SFS_IP = 'ip';
    public static SFS_LAG_MONITOR = 'lag_monitor';

    //ROOM GROUPS
    public static ROOM_GROUP_DEFAULT = 'default';

    //SEAT AVAILABILITY STATE
    public static SEAT_OPEN = 0;
    public static SEAT_LOCKED = 1;
    public static SEAT_BUDDY = 2;

    //TABLE PRIVACY STATE
    public static TSFSGAME_LOCKED = 0;
    public static SFSGAME_BUDDY = 1;
    public static TSFSGAME_OPEN = 2;

    //TABLE MODE STATE
    public static SFSGAME_GENERIC = 0;
    public static SFSGAME_NOROBOTS = 1;
    public static SFSGAME_TOURNAMENT = 3;

    //MESSAGIUNG PROTOCOL
    public static MESSAGE_ID = 'mid';

    //ADMIN / MODERATOR message int numbers
    public static SFS_ADMIN_GENERIC = 0;
    public static SFS_ADMIN_FAILED_TO_CREATE = 100;
    public static SFS_ADMIN_FAILED_TO_JOIN = 200;

    public static SFS_MOD_TAKEOVER = 300;
    public static SFS_ADMIN_PLEASE_UNBLOCK = 400;

    //Shark Bridge Grand Central Engine Messages
    public static MAKE_CALL = 'b1';
    public static MADE_CALL = 'b2';
    public static HINT_CALL = 'b3';
    public static SUGGESTED_CALL = 'b4';
    public static UNDO_CALL = 'b5';
    public static EXPL_CALL = 'b6';

    public static MAKE_PLAY = 'b7';
    public static MADE_PLAY = 'b8';
    public static SUGGESTED_PLAY = 'b9';
    public static HINT_PLAY = 'b10';
    public static UNDO_PLAY = 'b11';

    public static REQUEST_HINT = 'b12';

    public static CLOSE_TRICK = 'b13';
    public static CLAIM_RESPONSE = 'b14';

    public static OPEN_DEALSET = 'b15';
    public static URL = 'b16';

    public static NET_JOIN_ROOM = 'b17';
    public static NET_GET_CURRENT_DEAL = 'b18';

    public static UPDATE_ENGINE_CONFIG = 'b19';
    public static UNDO_RESPONSE = 'b20';

    public static NEXT_DEAL = 'b21';
    public static SET_CURRENT_HANDCONTROL = 'b22';
    public static REQUEST_UNDO = 'b23';
    public static NET_KIBITZ_ROOM = 'b24';
    public static LOAD_BID_DATABASE = 'b25';
    public static NET_AUTHENTICATE = 'b25';
    public static CLOSE_DEALSET = 'b26';
    public static GET_EXPL_CALL = 'b27';
    public static NEXT_DEAL_FROM_FILE = 'b28';

    public static DEAL_FORMAT = 'b29';

    public static netMID = netMID;
    public static gameMode = ENUM_GAME_MODE;
    public static gameStates = GameStates;
}

export enum SFS_HAND_PROPS {
    north = 'nprops',
    east = 'eprops',
    south = 'sprops',
    west = 'wprops',
    center = 'cprops',
}

export class SFS_NAV{
    public static CMD_NEXT_POINT = "NEXT_POINT";
    public static CMD_PREV_POINT = "PREV_POINT";
    public static CMD_NEXT_SCREEN = "NEXT_SCREEN";
    public static CMD_LOAD_TRAINING = "LOAD_TRAINING";
    public static CMD_AUTOBID = "CMD_AUTOBID";
    public static CMD_ANSWER = "CMD_ANSWER";
    public static CMD_UNDO = "CMD_UNDO";
    public static CMD_REPLAY = "CMD_REPLAY";
    public static CMD_FAV = "CMD_FAV";
    public static CMD_LOAD_INDEX = "CMD_INDEX";
    public static CMD_LOAD_SETTINS = "CMD_SETTINGS";
}

export class SFS_EXT{
    public static EXT_COLUMN_INDEX = "EXT_CI";
}