import {BridgePosition, Call, CardId, SeatPosition, SuitOrder, suits} from '../app/types';

export const strToCardId = (sfsCode: string): CardId => {
    return sfsCode.toLowerCase() as CardId;
};

export const strToCall = (sfsCode: string): Call => {
    if (sfsCode === '?') {
        return 'q' as Call;
    }
    if (sfsCode === 'X' || sfsCode === 'x') {
        return 'xdbl' as Call;
    }
    if (sfsCode === 'XX' || sfsCode === 'xx') {
        return 'rdbl' as Call;
    }
    return sfsCode.toLowerCase() as Call;
};

export const convertIntToCall = (callId: number): Call => {
    //43
    if (callId === 0) {return 'Pass' as Call;}
    const levelIndex = Math.floor(callId / 10); // 4
    const strainIndex = callId % 10; // 3
    return strToCall(`${levelIndex}${convertIntToSuitStr(strainIndex)}`);
};

export const strToSize = (sfsCode: string): 's' | 'm' | 'l' => {
    switch (sfsCode) {
        case 's':
            return 's';
        case 'n':
            return 'm';
        case 'l':
            return 'l';
    }
    return 'm';
};

export const sizeToCardMultiplier = (sfsCode: string, addextra: number): number => {
    switch (sfsCode) {
        case 's':
            return 0.8 + addextra;
        case 'n':
            return 1 + addextra;
        case 'l':
            return 1 + addextra;
    }
    return 1 + addextra;
};

export const fixColor = (sfsCode: string): string => {
    switch (sfsCode.toLowerCase()) {
        case 'y':
            return '#fce303';
        case 'g':
            return 'green';
        case 'r':
            return 'red';
    }
    return sfsCode;
};

/*
public static  enum  SuitOrder {
		SHCD,0
		HSDC,1
		DSHC,2
		CHSD,3
		SDCH,4
		HCDS,5
		DCHS,6
		CDSH,7
		ENGINE 8
	};
 */
export const intToSuitOrder = (sfsCode: number): SuitOrder => {
    switch (sfsCode) {
        case 0:
            return SuitOrder.SHCD;
        case 1:
            return SuitOrder.HSDC;
        case 2:
            return SuitOrder.DSHC;
        case 3:
            return SuitOrder.CHSD;
        case 4:
            return SuitOrder.SDCH;
        case 5:
            return SuitOrder.HCDS;
        case 6:
            return SuitOrder.DCHS;
        case 7:
            return SuitOrder.CDSH;
        case 8:
            return SuitOrder.ENGINE;
    }

    return SuitOrder.SHCD;
};

export const convertIntToBridgePosition = (pos: number): BridgePosition => {
    switch (pos) {
        case 0:
            return BridgePosition.north;
        case 1:
            return BridgePosition.east;
        case 2:
            return BridgePosition.south;
        case 3:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const convertPlayerToBridgePosition = (pos: number): BridgePosition => {
    switch (true) {
        case (pos & 1) !== 0:
            return BridgePosition.north;
        case (pos & 2) !== 0:
            return BridgePosition.east;
        case (pos & 4) !== 0:
            return BridgePosition.south;
        case (pos & 8) !== 0:
            return BridgePosition.west;
    }
    return BridgePosition.south;
};

export const convertPlayerToIntBridgePosition = (pos: number): number => {
    switch (true) {
        case (pos & 1) !== 0:
            return 0;
        case (pos & 2) !== 0:
            return 1;
        case (pos & 4) !== 0:
            return 2;
        case (pos & 8) !== 0:
            return 3;
    }
    return -1;
};

export const convertBridgePositionToInt = (pos: BridgePosition | undefined): number => {
    switch (pos) {
        case BridgePosition.north:
            return 0;
        case BridgePosition.east:
            return 1;
        case BridgePosition.south:
            return 2;
        case BridgePosition.west:
            return 3;
    }
    return -1;
};

export const convertDummyToDeclarer = (pos: BridgePosition): BridgePosition => {
    switch (pos) {
        case BridgePosition.north:
            return BridgePosition.south;
        case BridgePosition.east:
            return BridgePosition.west;
        case BridgePosition.south:
            return BridgePosition.north;
        case BridgePosition.west:
            return BridgePosition.east;
    }
    return BridgePosition.south;
};

export const getCardState = (hand: string, card: string, state: string): boolean => {
    let startWithSuit = 3;
    try {
        const chars = [...hand];
        let truState = false;
        chars.forEach((c, i) => {
            if (c === state) {
                if (card[0] === convertIntToSuitStr(startWithSuit) && card[1] === chars[i + 1]) {
                    truState = true;
                }
            } else if (c === '.') {
                startWithSuit--;
            } else if (c === ' ') {
                startWithSuit = 3;
            }
        });
        return truState;
    } catch {
        return false;
    }
};

export const convertIntToSuitStr = (suit: number): string => {
    switch (suit) {
        case 0:
            return 'C';
        case 1:
            return 'D';
        case 2:
            return 'H';
        case 3:
            return 'S';
        case 4:
            return 'NT';
    }
    return '';
};

export const bridgePosToSeatPos = (b: BridgePosition | undefined): SeatPosition => {
    switch (b) {
        case BridgePosition.north:
            return SeatPosition.top;
        case BridgePosition.east:
            return SeatPosition.right;
        case BridgePosition.south:
            return SeatPosition.bottom;
        case BridgePosition.west:
            return SeatPosition.left;
        default:
            return SeatPosition.top;
    }
};
