import './App.scss';
import { FooterComponent } from './features/footer-component/footer-component';
import { RootComponent } from './features/root-component/root-component';
import GameEngineInterface from './app-interfaces/game-engine-interface.class';
import React from 'react';

function App() {
    return (
        <div className="App">
            <GameEngineInterface />
            <RootComponent />
            <FooterComponent />
        </div>
    );
}

export default App;
